import * as cognitoAuth from "aws-amplify/auth";

export function signUp(email, password, name, phoneNumber) {
  return cognitoAuth.signUp({
    username: email.toLowerCase().trim(),
    password: password,
    options: {
      userAttributes: {
        name: name,
        email: email,
        phone_number: phoneNumber,
      },
    },
  });
}

export function confirmSignUp(email, code) {
  return cognitoAuth.confirmSignUp({
    username: email,
    confirmationCode: code,
  });
}

export function resendConfirmationCode(email) {
  return cognitoAuth.resendSignUpCode({ username: email });
}

export async function signIn(email, password) {
  try {
    // Check if there is an existing user, sign him out prior to calling signin
    const currentUser = await cognitoAuth.getCurrentUser();
    if (currentUser) {
      await cognitoAuth.signOut();
    }
  } catch (_) {
    /* empty */
  }
  return cognitoAuth.signIn({ username: email.toLowerCase(), password });
}

export function confirmSignIn(totpCode) {
  return cognitoAuth.confirmSignIn({ challengeResponse: totpCode });
}

export function setupTOTP() {
  return cognitoAuth.setUpTOTP();
}

export async function verifyTOTP(totp) {
  return cognitoAuth.verifyTOTPSetup({ code: totp });
}

export async function currentAuthenticatedUser() {
  const currentUser = await cognitoAuth.getCurrentUser();
  return currentUser;
}

export function setPreferredMFA(mfaMethod) {
  return cognitoAuth.updateMFAPreference({ [mfaMethod]: "PREFERRED" });
}

export function signOut() {
  return cognitoAuth.signOut();
}

export function forgotPassword(email) {
  return cognitoAuth.resetPassword({ username: email.toLowerCase().trim() });
}

export function forgotPasswordSubmit(email, confirmationCode, newPassword) {
  return cognitoAuth.confirmResetPassword({
    username: email,
    newPassword,
    confirmationCode,
  });
}

export async function userIsLoggedIn() {
  try {
    await cognitoAuth.getCurrentUser();
  } catch {
    return false;
  }
}
