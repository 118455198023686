<template>
  <div class="d-sm-inline-block">
    <div class="fw-medium-bold mb-2">
      {{ props.inputLabel
      }}<i
        v-if="props.tooltipContent"
        class="bi bi-info-circle ms-2"
        id="info-icon"
        data-toggle="tooltip"
        :title="props.tooltipContent"
      ></i>
    </div>
    <div class="d-flex flex-md-row flex-wrap flex-column">
      <div
        v-for="option in props.options"
        v-bind:key="option.value"
        class="me-md-2 mb-2 mb-md-0"
      >
        <Field
          :name="props.inputName"
          type="radio"
          :value="props.modelValue"
          :rules="
            props.crossCheckValidator
              ? props.validator
              : () => validator(props.modelValue)
          "
          v-slot="{ handleChange, errors }"
        >
          <label
            :for="props.inputName + option.value"
            class="form-check-label btn btn-light d-flex align-items-center flex-nowrap radio-label px-3"
            :class="{ 'is-invalid': !!errors.length }"
          >
            <input
              :id="props.inputName + option.value"
              :name="props.inputName"
              type="radio"
              class="form-check-input me-2 mt-0"
              :class="{ 'is-invalid': !!errors.length }"
              @input="
                {
                  $emit('update:modelValue', option.value);
                }
              "
              @change="handleChange"
              :checked="props.modelValue === option.value"
            />
            {{ option.label }}
            <span class="checkmark"></span>
          </label>
        </Field>
      </div>
      <div class="" v-if="hasOtherOption">
        <Field
          :name="props.inputName"
          type="radio"
          :value="props.modelValue"
          :rules="() => validator(props.modelValue)"
          v-slot="{ handleChange, errors }"
        >
          <label
            class="form-check-label btn btn-light d-flex align-items-center flex-nowrap radio-label"
            :class="{ 'is-invalid': !!errors.length }"
          >
            <input
              :id="props.inputName + 'Other'"
              :name="props.inputName"
              type="radio"
              class="form-check-input me-2 mt-0"
              :class="{ 'is-invalid': !!errors.length }"
              @input="handleOtherRadioCheck"
              @change="handleChange"
              :checked="otherRadioIsChecked()"
              ref="otherRadioBullet"
            />
            <input
              type="text"
              :placeholder="props.otherOptionPlaceholder"
              class="form-control my-0 other-option-input"
              :value="otherSpecification"
              @keyup="handleChange"
              @input="
                ((otherSpecification = $event.target.value),
                handleOtherSpecificationChange())
              "
              :tabindex="!otherRadioIsChecked() && -1"
            />
          </label>
        </Field>
      </div>
    </div>
    <div class="mt-1 ms-1">
      <ErrorMessage
        class="error-message"
        :name="props.inputName"
        :id="props.inputName + 'ErrorMessage'"
      />
    </div>
  </div>
</template>

<script setup>
import { Field, ErrorMessage } from "vee-validate";
import { ref } from "vue";
const props = defineProps([
  "modelValue",
  "inputLabel",
  "inputName",
  "validator",
  "options",
  "hasOtherOption",
  "otherOptionPlaceholder",
  "tooltipContent",
  "checked",
  "crossCheckValidator",
]);

const otherRadioBullet = ref(null);

function setInitOtherSpecification() {
  if (props.options.map(({ value }) => value).includes(props.modelValue))
    return "";
  return props.modelValue;
}

const otherSpecification = ref(setInitOtherSpecification());
const emit = defineEmits(["update:modelValue"]);

function handleOtherSpecificationChange() {
  otherRadioBullet.value.checked = true;
  emit("update:modelValue", otherSpecification.value);
}

function handleOtherRadioCheck() {
  emit("update:modelValue", otherSpecification.value ?? "");
}

function otherRadioIsChecked() {
  if (props.modelValue === null) return false;
  if (!props.options.map(({ value }) => value).includes(props.modelValue))
    return true;
}
</script>
<style lang="scss">
.other-option-input {
  width: 180px !important;
  height: 30px;
  border-radius: 6px;
}
.radio-label {
  height: 48px;
  align-items: center;
}

label.radio-label {
  border: 1px solid #aeb0b2;
}

label.radio-label.is-invalid {
  border: 1px red solid;
}
</style>
