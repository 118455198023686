interface VersionInfo {
  buildNumber: string;
  buildDate: string;
  commitHash: string;
}

export const versionInfo: VersionInfo = {
  buildNumber: "1631547016",
  buildDate: "2025-01-19T13:05:47.",
  commitHash: "4b1588e12c74e81ee6fa1a6f6b70a58175240163",
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).APP_VERSION_INFO = versionInfo;
export default versionInfo;
